import styles from "./Dosage.module.scss";

export default function Dosage(props) {
  const { percent_cbd, percent_thc, dosage, category } = props;

  const formatPercentage = (value) => {
    return value.toFixed(1); // Ensure only one decimal place
  };

  if (percent_thc && percent_cbd >= 1) {
    return (
      <>
        <span className={styles.thc} attribute="thc">
          {formatPercentage(percent_thc)}
        </span>
        <span className={styles.cbd} attribute="cbd">
          {formatPercentage(percent_cbd)}
        </span>
      </>
    );
  } else if (percent_thc) {
    return (
      <span className={styles.thc} attribute="thc">
        {formatPercentage(percent_thc)}
      </span>
    );
  } else if (percent_cbd) {
    return (
      <span className={styles.cbd} attribute="thc">
        {formatPercentage(percent_cbd)}
      </span>
    );
  } else if (dosage) {
    if (dosage.includes("/")) {
      let arr = dosage.split("/");
      return (
        <>
          {arr.map((i) => {
            let str1 = i.split(" ")[0];
            let str2 = i.split(" ")[1];
            return (
              <span key={i} className={styles.dosage} attribute="dosage">
                <span className="color-neutral-650">{str2} </span>
                {str1}
              </span>
            );
          })}
        </>
      );
    } else {
      return (
        <span className={styles.dosage} attribute="dosage">
          <span className="color-neutral-650">
            {category === "cbd" ? "CBD " : "THC "}
          </span>
          {dosage}
        </span>
      );
    }
  } else return;
}
